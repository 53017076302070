import { AxiosStatic } from 'axios';
import OliAxios from 'api/util/OliAxios';
import { getConfig } from 'api/util/getConfig';
import {
  DownloadResponse, DownloadParams, DeleteParams,
} from '../FileTransfer.types';

async function downloadFile(token:string | undefined, params:DownloadParams, retryCount = 0):Promise<AxiosStatic | undefined> {
  if (token) {
    let retry = retryCount;
    const config = getConfig(token);
    const url = '/fileTransfer/download';
    try {
      const response:DownloadResponse = await OliAxios.get(url, {
        ...config, params,
      });

      const { downloadURL } = response.data;
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = params.path;
      if (!params.isDownload) {
        link.target = '_blank';
      }
      link.click();
    } catch (e) {
      if (retry < 2) {
        downloadFile(token, params, retry += 1);
      }
    }
  }
  return undefined;
}

async function deleteFile(token:string | undefined, params:DeleteParams, retryCount = 0):Promise<AxiosStatic | undefined> {
  if (token) {
    let retry = retryCount;
    const config = getConfig(token);
    const url = '/fileTransfer/delete';
    try {
      return await OliAxios.get(url, {
        ...config, params,
      });
    } catch (e) {
      if (retry < 2) {
        deleteFile(token, params, retry += 1);
      }
    }
  }
  return undefined;
}

export {
  deleteFile, downloadFile,
};
