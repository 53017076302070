const useStyles = {
  button: {
    marginRight: '10px',
    color: 'white',
  },
  appBar: {
    backgroundColor: 'rgba(61, 55, 65, .5)',
  },
};

export { useStyles };
