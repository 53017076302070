import Iframe from 'components/Iframe/Iframe';
import React, {
  useEffect, useState,
} from 'react';
import {
  AppBar, Toolbar,
  Button,
} from '@mui/material';
import { ISupersetUrl } from './Superset.types';
import { useStyles } from './Superset.jss';

const Superset: React.FunctionComponent = () => {
  const [url, setUrl] = useState<string>('');
  const styles = useStyles;
  const prefix = 'https://superset-dev.olivertechnology.com/superset/dashboard';
  const postfix = '?preselect_filters=%7B%7D&standalone=true&native_filters=%28%29';
  const sources:ISupersetUrl[] = [
    {
      name: 'Executive Overview',
      src: `${prefix}/executive/${postfix}`,
    },
    {
      name: 'Milestone Performance',
      src: `${prefix}/milestoneperformance/${postfix}`,
    },
    {
      name: 'Balances',
      src: `${prefix}/balances/${postfix}`,
    },
    {
      name: 'Recoveries',
      src: `${prefix}/recoveries/${postfix}`,
    },
    {
      name: 'Costs',
      src: `${prefix}/costs/${postfix}`,
    },
    {
      name: 'Velocity',
      src: `${prefix}/velocity/${postfix}`,
    },
    {
      name: 'Performance',
      src: `${prefix}/performance/${postfix}`,
    },
  ];

  const setDashboard = (name:string):void => {
    const newsrc = sources.find((e) => e.name === name)?.src;
    if (newsrc && newsrc !== url) {
      setUrl(newsrc);
    }
  };

  useEffect(() => {
    setDashboard('Executive Overview');
  }, []);

  return (
    <>
      <AppBar position="static" style={styles.appBar}>
        <Toolbar>
          {sources.map((source) => (
            <Button key={source.name} sx={styles.button} onClick={() => setDashboard(source.name)} variant={url === source.src ? 'contained' : 'text'}>{source.name}</Button>
          ))}
        </Toolbar>
      </AppBar>
      <Iframe title="Superset Dashboard" src={url} />
    </>
  );
};

export default Superset;
