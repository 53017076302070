import React from 'react';
import { Typography } from '@mui/material';
import { useStyles } from './Header.jss';
import { IHeaderProps } from './Header.types';

const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  const {
    text, period, variant, short, space,
  } = props;

  const classes = useStyles;
  const Tag = variant as keyof JSX.IntrinsicElements || 'h1';
  // const styles = short ? { marginTop: '0px' } : {};
  let styles = {};
  if (space && space === 'left') {
    styles = { marginLeft: '5px' };
  } else if (short) {
    styles = { marginTop: '0px' };
  }

  return (
    <Typography component="span">
      <Tag style={{
        ...styles, ...classes.header,
      }}
      >
        {text}
        {period ? (<span style={classes.period}>.</span>) : (<span />)}
      </Tag>
    </Typography>
  );
};

export default Header;
