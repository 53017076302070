import React, {
  useMemo, useState, useContext,
} from 'react';
import {
  Grid, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Paper, Button, Alert, Snackbar,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import Header from 'components/Header/Header';
import UserContext from 'context/UserContext';
import { useStyles } from './SmartUploader.jss';
import {
  parseSmart, uploadFiles,
} from './SmartUploader.api';
import {
  ParseSmartParams, ParseSmartResponse, Files,
} from '../FileTransfer.types';

const SmartUploader: React.FunctionComponent = () => {
  const token = useContext(UserContext)?.token;
  const [files, setFiles] = useState<Files[]>([]);
  const [error, setError] = useState<Error>();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFail, setOpenFail] = useState(false);

  const closeSnackBar = ():void => {
    setOpenSuccess(false);
    setOpenFail(false);
  };

  const setData = (result:ParseSmartResponse | undefined, acceptedFiles:File[]):void => {
    const data = result?.data;
    if (data) {
      const temp:Files[] = [];
      acceptedFiles.forEach((file) => {
        const found = data.find((d) => d.filename === file.name);
        if (found) {
          temp.push({
            ...found, file,
          });
        }
      });
      setFiles(temp);
    }
  };

  const uploadConfirmed = async ():Promise<void> => {
    try {
      const promises:Promise<unknown>[] = [];
      files.forEach((file) => {
        if (file.willUpload) {
          promises.push(uploadFiles(token, file.file, [file.filename], file.tag, file.fileNo));
        }
      });
      await Promise.all(promises);
      setOpenSuccess(true);
      setFiles([]);
    } catch (e) {
      setOpenFail(true);
      setError(e as Error);
    }
  };

  const {
    getRootProps, getInputProps, isFocused, isDragAccept, isDragReject,
  } = useDropzone({
    noClick: true,
    accept: {
      'application/pdf': [],
    },
    onDrop: (acceptedFiles) => {
      const filenames = acceptedFiles.map((file) => file.name);
      const params:ParseSmartParams = { filenames };
      parseSmart(token, params).then((result) => setData(result, acceptedFiles));
    },
  });

  const classes = useStyles;

  const style:React.CSSProperties = useMemo(() => ({
    ...classes.baseStyle,
    ...(isFocused ? classes.focusedStyle : {}),
    ...(isDragAccept ? classes.acceptStyle : {}),
    ...(isDragReject ? classes.rejectStyle : {}),
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
  ]);

  const renderTable = ():JSX.Element => {
    if (files.length > 0) {
      return (
        <Grid
          item
          xs={8}
          sx={{
            paddingLeft: '7px', paddingRight: '7px',
          }}
        >
          <Header text="Files to upload" period />
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>File Name</TableCell>
                  <TableCell align="right">Tag</TableCell>
                  <TableCell align="right">File Number / FILENO</TableCell>
                  <TableCell align="right">Will Upload?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((row) => (
                  <TableRow
                    key={row.filename}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.filename}
                    </TableCell>
                    <TableCell align="right">{row.tag}</TableCell>
                    <TableCell align="right">{row.fileNo}</TableCell>
                    <TableCell align="right">{row.willUpload ? 'true' : 'false'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Button color="secondary" variant="contained" component="span" onClick={() => uploadConfirmed()}>
            Upload
          </Button>
          &nbsp;
          <Button color="primary" variant="contained" component="span" onClick={() => setFiles([])}>
            Clear
          </Button>
        </Grid>
      );
    }
    return <></>;
  };

  return (
    <>
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={8}>
          <div className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p>Smart Upload</p>
            </div>
          </div>
        </Grid>
        {renderTable()}
        <Snackbar
          open={openSuccess}
          onClose={closeSnackBar}
        >
          <Alert onClose={closeSnackBar} severity="success" sx={classes.alert}>
            File Upload Complete.
          </Alert>
        </Snackbar>
        <Snackbar
          open={openFail}
          onClose={closeSnackBar}
        >
          <Alert onClose={closeSnackBar} severity="error" sx={classes.alert}>
            Something has gone wrong. (
            {error?.message}
            )
          </Alert>
        </Snackbar>
      </Grid>
    </>
  );
};

export default SmartUploader;
