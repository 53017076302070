import React, { useMemo } from 'react';
import {
  Grid,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { IDropzoneProps } from './Dropzone.types';

const Dropzone: React.FunctionComponent<IDropzoneProps> = (props) => {
  const {
    tag, icmp, handleUpload, setOpenSuccess, setOpenFail, setError,
  } = props;

  const handleError = (er: Error):void => {
    setOpenFail(true);
    setError(er);
  };

  const {
    getRootProps, getInputProps, isFocused, isDragAccept, isDragReject,
  } = useDropzone({
    noClick: true,
    accept: {
      'application/pdf': [],
    },
    onDrop: (acceptedFiles) => {
      const filenames = acceptedFiles.map((file) => file.name);
      handleUpload(acceptedFiles as unknown as FileList, filenames, tag as string, 0)
        .then(() => setOpenSuccess(true))
        .catch((er) => handleError(er));
    },
  });

  const dropText = `ICMP ${icmp} Dropzone`;

  const baseStyle = {
    margin: '5px',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const focusedStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

  const style:React.CSSProperties = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
  ]);

  return (
    <>
      <Grid item>
        <div
          className="container"
        >
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p>
              {tag}
              <br />
              {dropText}
            </p>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default Dropzone;
