import {
  useState, useEffect,
} from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  IResult,
} from './useUser.types';
import { getUserDetails } from './useUser.api';

function useUser(user?: CognitoUser): IResult {
  const [token, setToken] = useState<string>('');
  const [type, setType] = useState<string>('firm');
  const [name, setName] = useState<string>('');

  useEffect(() => {
    if (!user || !user.getSignInUserSession) return;
    const jwtToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();
    if (jwtToken) {
      setToken(jwtToken);
    }
  }, [user]);

  useEffect(() => {
    if (!token) return;
    getUserDetails(token)
      .then((data) => { setName(data.data.name); setType(data.data.type); });
  }, [token]);

  return {
    token, type, name,
  };
}

export default useUser;
