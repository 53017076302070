import React from 'react';
import {
  createTheme, ThemeProvider,
  AppBar, Toolbar, Typography,
  Button,
} from '@mui/material';
import UserContext from 'context/UserContext';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import IdleTimerModal from 'components/IdleTimerModal/IdleTimerModal';
import Home from 'components/Home';
import Logo from 'components/Logo/Logo';
import Superset from 'components/Superset/Superset';
import useUser from 'hooks/useUser.hook';
import {
  BrowserRouter, Routes, Route, Link,
} from 'react-router-dom';
import FileTransfer from 'components/FileTransfer/FileTransfer';
import { useStyles } from './Adt.jss';
import { AdtProps } from './Adt.types';

const Adt: React.FunctionComponent<AdtProps> = (props) => {
  const {
    user, signOut,
  } = props;

  const showInsights = window.location.href.includes('demo');

  const styles = useStyles;

  const theme = createTheme({
    palette: {
      primary: {
        main: '#3e3842', // slate
      },
      secondary: {
        main: '#ca304c', // red
      },
    },
  });

  const {
    token, type, name,
  } = useUser(user);

  const oliverSignOut = ():void => {
    signOut(); //eslint-disable-line
  };

  const mailSupport = ():void => {
    window.open('https://olivertechnology.atlassian.net/servicedesk/customer/portals');
  };

  const renderInsightsLink = ():JSX.Element => {
    if (showInsights) {
      return <Link style={styles.link} to="/insights"><Button sx={styles.button}>Insights</Button></Link>;
    }
    return <></>;
  };

  const renderInsightsRoute = ():JSX.Element => {
    if (showInsights) {
      return <Route path="insights" element={<Superset />} />;
    }
    return <></>;
  };

  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider value={{
        user, token, type, name,
      }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <div className="App">
            <BrowserRouter>
              <AppBar position="sticky" sx={styles.appBar}>
                <Toolbar>
                  <Logo router />
                  <Typography variant="h6" sx={styles.title} />
                  <Link style={styles.link} to="/"><Button sx={styles.button}>Home</Button></Link>
                  {renderInsightsLink()}
                  <Link style={styles.link} to="/filetransfer"><Button sx={styles.button}>File Transfer</Button></Link>
                  <Button sx={styles.button} onClick={() => mailSupport()}>Support</Button>
                  <Button sx={styles.button} onClick={() => oliverSignOut()}>Sign Out</Button>
                </Toolbar>
              </AppBar>
              <Routes>
                <Route path="/" element={<Home />} />
                {renderInsightsRoute()}
                <Route path="filetransfer" element={<FileTransfer />} />
                <Route path="*" element={<Home />} />
              </Routes>
            </BrowserRouter>
            <IdleTimerModal signOut={() => oliverSignOut()} />
          </div>
        </LocalizationProvider>
      </UserContext.Provider>
    </ThemeProvider>
  );
};

export default Adt;
