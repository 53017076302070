import { TagsMap } from '../FileTransfer.types';

const tags:TagsMap = {
  FIRM: [
    {
      tag: 'ABS', icmp: 'ZZ182',
    },
    {
      tag: 'ADL', icmp: '20005',
    },
    {
      tag: 'AFF', icmp: '10023',
    },
    {
      tag: 'ANS', icmp: '19923',
    },
    {
      tag: 'ARB', icmp: '19911',
    },
    {
      tag: 'BKD', icmp: '19901',
    },
    {
      tag: 'CFC', icmp: '00000',
    },
    {
      tag: 'CLI', icmp: '20002',
    },
    {
      tag: 'COM', icmp: '20009',
    },
    {
      tag: 'DOD', icmp: '00000',
    },
    {
      tag: 'DSC', icmp: '19906',
    },
    {
      tag: 'DSM', icmp: 'ZZ068',
    },
    {
      tag: 'DVC', icmp: '20002',
    },
    {
      tag: 'JUD', icmp: '19907',
    },
    {
      tag: 'LPO', icmp: '19904',
    },
    {
      tag: 'MLD', icmp: '17804',
    },
    {
      tag: 'MTN', icmp: '19911',
    },
    {
      tag: 'PJE', icmp: '19906',
    },
    {
      tag: 'ROS', icmp: '10080',
    },
    {
      tag: 'SAT', icmp: '19905',
    },
    {
      tag: 'SSA', icmp: '10149',
    },
    {
      tag: 'UID', icmp: '19906',
    },
  ],
  CREDITOR: [
    {
      tag: 'ANS', icmp: '19923',
    },
    {
      tag: 'APD', icmp: '00000',
    },
    {
      tag: 'CAD', icmp: '00000',
    },
    {
      tag: 'CAM', icmp: '00000',
    },
    {
      tag: 'CFC', icmp: '00000',
    },
    {
      tag: 'CLI', icmp: '20002',
    },
    {
      tag: 'CLO', icmp: '00000',
    },
    {
      tag: 'DOD', icmp: '00000',
    },
    {
      tag: 'DVC', icmp: '20002',
    },
    {
      tag: 'LLC', icmp: '00000',
    },
    {
      tag: 'LPO', icmp: '19904',
    },
    {
      tag: 'MLD', icmp: '17804',
    },
    {
      tag: 'NAC', icmp: '00000',
    },
    {
      tag: 'RTC', icmp: '00000',
    },
    {
      tag: 'SLD', icmp: '00000',
    },
  ],
  VENDOR: [],
};

export default tags;
