import axios from 'axios';
import { getConfig } from 'api/util/getConfig';
import awsconfig from '../aws-exports';

type UserResponse = {
  data: UserDataResponse,
};

type UserDataResponse = {
  identifier: string,
  name: string,
  type: string,
};

export function getUserDetails(token:string):Promise<UserResponse> {
  const config = getConfig(token);
  const baseUrl = (awsconfig).aws_cloud_logic_custom[0].endpoint;
  const url = `${baseUrl}/util/user-details`;
  return axios.get(url, { ...config });
}
