import React, { useContext } from 'react';
import UserContext from 'context/UserContext';
import {
  Paper,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import MaterialTable from '@material-table/core';
import moment from 'moment';
import { AxiosStatic } from 'axios';
import {
  downloadFile, deleteFile,
} from './List.api';
import {
  Data, FileTransferListProps, DownloadParams, DeleteParams,
} from '../FileTransfer.types';

const List: React.FunctionComponent<FileTransferListProps> = (props) => {
  const {
    data, fileNo, getFileData,
  } = props;

  const modifyData = (filedata:Data[] = []):Data[] => filedata.map((element) => ({
    ...element, uploaded: moment(element.uploaded).format('MM/DD/YYYY'),
  }));

  const token = useContext(UserContext)?.token;

  const handleDownload = async (filedata:Data[], isDownload:boolean):Promise<void> => {
    const promises:Promise<AxiosStatic | undefined>[] = [];
    filedata.forEach((d) => {
      const params:DownloadParams = {
        path: d.path, fileID: d.id, isDownload,
      };
      promises.push(downloadFile(token, params));
    });
    await Promise.all(promises);
  };

  const handleDelete = async (fileData:Data[]):Promise<void> => {
    const promises:Promise<AxiosStatic | undefined>[] = [];
    fileData.forEach((d) => {
      const params:DeleteParams = {
        fileID: d.id, file_no: fileNo,
      };
      promises.push(deleteFile(token, params));
    });
    await Promise.all(promises);
    getFileData();
  };

  return (
    <>
      <Paper sx={{
        width: '95%', overflow: 'hidden',
      }}
      >
        <MaterialTable
          title=""
          columns={[
            {
              title: 'Tags',
              field: 'tags',
              headerStyle: {
                fontWeight: 'bold',
              },
            },
            {
              title: 'Name',
              field: 'name',
              headerStyle: {
                fontWeight: 'bold',
              },
            },
            {
              title: 'Uploaded On',
              field: 'uploaded',
              headerStyle: {
                fontWeight: 'bold',
              },
            },
          ]}
          data={modifyData(data)}
          options={{ selection: true }}
          actions={[
            {
              icon: () => <VisibilityIcon />,
              tooltip: 'View File',
              onClick: (_e, rowData) => handleDownload(rowData as Data[], false),
            },
            {
              icon: () => <DownloadIcon />,
              tooltip: 'Download File',
              onClick: (_e, rowData) => handleDownload(rowData as Data[], true),
            },
            {
              icon: () => <DeleteIcon />,
              tooltip: 'Delete File',
              onClick: (_e, rowData) => handleDelete(rowData as Data[]),
            },
          ]}
        />
      </Paper>
    </>
  );
};

export default List;
